import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef, HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID, Renderer2, ViewChild
} from "@angular/core";
import { interval, Subject, Subscription, takeUntil } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { Tooltip } from "primeng/tooltip";
import { ComAPIService } from "../API/com-api.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-preview-auction',
  templateUrl: './preview-auction.component.html',
  styleUrls: ['./preview-auction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, ConfirmationService],

})

export class PreviewAuctionComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('style.display') display: string;
  @Input() auction: any;
  @Input() isActive: string;
  @Input() isAdmin: boolean = false;
  imageSRC: string = '';

  first_register: any;
  endAuction: any;
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  showTimer = true;
  id: number | ReturnType<typeof setTimeout> = 0;
  gallerySpinner = true;
  openAuction: string[] = [];
  width: string;
  height: string;
  imageError: boolean;
  intervalTime = 1000;
  @ViewChild('image') imageRef: ElementRef;
  private subscription: Subscription;
  showSecondsTimer = true;
  showDaysTimer = true;
  @ViewChild(Tooltip) tooltip!: Tooltip;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private ref: ChangeDetectorRef,
    private service: ComAPIService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private toastr: ToastrService,
    private readonly renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.endAuction = new Date(this.auction?.end_time).toLocaleString();
      this.first_register = new Date(
        this.auction?.first_register
      ).toLocaleDateString();
      if (this.auction) {
        this.openAuction = [`/aukcja/${this.auction?.auction_id}`];
        this.timeCalculator(this.auction?.end_time)
        this.subscription = interval(this.intervalTime)
          .subscribe( () => { this.timeCalculator(this.auction?.end_time) });
      }
    }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId) && this.auction) {
      this.width = this.imageRef.nativeElement.offsetWidth;
      this.height = this.imageRef.nativeElement.offsetHeight;
      this.imageSRC = `../api/images/${this.auction?.primary_image}?width=${this.width}&height=${this.height}`;
      this.gallerySpinner = false
      this.ref.detectChanges()
    }
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  timeCalculator(endAuction: any): void {
    // this.ngZone.runOutsideAngular(() => {
      const total = Date.parse(endAuction) - Date.parse(`${new Date()}`);
      this.days = Math.floor(total / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      this.minutes = Math.floor((total / 1000 / 60) % 60);
      this.seconds = Math.floor((total / 1000) % 60);

      this.ref.detectChanges();
      // console.log(total, days, hours, minutes, seconds);
      if (this.days > 0) {
        this.intervalTime = 60000
        this.showSecondsTimer = false;
        this.showDaysTimer = true;

      } else {
        this.intervalTime = 1000
        this.showSecondsTimer = true;
        this.showDaysTimer = false;
      }

      if (total < 0) {
        this.showTimer = false;
      }
      this.ref.detectChanges()
    // })
  }

  public onIntersection(event: any) {
    const { target, visible } = event;

    const newClassname = visible ? 'active' : 'inactive';
    this.renderer.addClass(target, newClassname);

    const oldClassname = visible ? 'inactive' : 'active';
    this.renderer.removeClass(target, oldClassname);

  }

  showTooltip() {
    this.tooltip.activate();
  }
  deleteAuction(auctionID: number, auction_ids: string) {
    this.service.deleteAuction(auctionID, auction_ids).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.display = 'none';
      this.toastr.success('Aukcja została usunięta');
    })
  }

  deleteSelectedAuction(auction: any) {
    console.log('auction', auction)
    this.confirmationService.confirm({
      message: `Jesteś pewień, że chcesz usunąć tę aukcję "${auction.mark} ${auction.model}"`,
      header: 'Zatwierdź',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteAuction(auction?.auction_id, auction?.auction_ids);

        this.ref.detectChanges();
      },
      reject: () => { console.log('Do nothing'); this.ref.detectChanges();}
    });
    this.ref.detectChanges();

  }

  imageLoaded($event: ErrorEvent) {
    if ($event.type === 'error') {
      this.display = 'none'
    }
  }
}
