<div class="background flex flex-column">
  <p-progressSpinner
    styleClass="absolute m-auto"
    class="m-auto"
    *ngIf="gallerySpinner"
    [strokeWidth]="'4'"
  ></p-progressSpinner>
  <div class="div-container">
    <gallery class="gallery" id="myGallery" (itemClick)="openGallery()" loadingStrategy="lazy" imageSize="cover"></gallery>
    <div class="preview-desc w-full">
      <h3 class="preview-title">{{ auction?.mark }} {{ auction?.model }}</h3>
      <ul class="preview-list">
        <li class="preview-list-item">
          <p>
            Serwis: <span>{{ auction?.serwis }}</span>
          </p>
        </li>
        <li class="preview-list-item">
          <p>
            Przebieg: <span>{{ auction?.mileage | number: "2." }} km</span>
          </p>
        </li>
        <li class="preview-list-item">
          <p>
            Pierwsza rejestracja:
            <span>{{ auction?.first_register | date: "dd.MM.yy" }}</span>
          </p>
        </li>
        <li class="preview-list-item">
          <p>
            Zakończenie aukcji:
            <span>{{ endAuction | date: "dd.MM.yy, HH:mm" }}</span>
          </p>
        </li>
      </ul>
      <div *ngIf="showTimer; else auctionEnded" class="timer">
        <ul class="timer-list">
          <li class="timer-list-item">
            <p class="timer-list-item-number">{{ days }}</p>
            <p class="timer-list-item-text">dni</p>
          </li>
          <li class="timer-list-item">
            <p class="timer-list-item-number">{{ hours }}</p>
            <p class="timer-list-item-text">godzin</p>
          </li>
          <li class="timer-list-item">
            <p class="timer-list-item-number">{{ minutes }}</p>
            <p class="timer-list-item-text">minut</p>
          </li>
          <li class="timer-list-item">
            <p class="timer-list-item-number">{{ seconds }}</p>
            <p class="timer-list-item-text">sekund</p>
          </li>
        </ul>
      </div>
      <ng-template #auctionEnded>
        <span class="text-2xl text-center">Aukcja zakończona</span>
      </ng-template>
    </div>
    <div class="opis-container">
      <p class="auction-text-desc">Opis</p>
      <table class="table">
        <tr *ngIf="auction?.mark">
          <td>Marka</td>
          <td>{{ auction?.mark }}</td>
        </tr>
        <tr *ngIf="auction?.model">
          <td>Model</td>
          <td>{{ auction?.model }}</td>
        </tr>
        <tr *ngIf="auction?.color">
          <td>Kolor</td>
          <td>{{ auction?.color }}</td>
        </tr>
        <tr *ngIf="auction?.price_new">
          <td>Wartość nowego</td>
          <td>{{ auction?.price_new }}</td>
        </tr>
        <tr *ngIf="auction?.price_actual">
          <td>Aktualna wartość</td>
          <td>{{ auction?.price_actual }}</td>
        </tr>
        <tr *ngIf="auction?.price_repair">
          <td>Koszt naprawy</td>
          <td>{{ auction?.price_repair }}</td>
        </tr>
        <tr *ngIf="auction?.inside">
          <td>Wnętrze</td>
          <td>{{ auction?.inside }}</td>
        </tr>
        <tr *ngIf="auction?.seats">
          <td>Siedzenia</td>
          <td>{{ auction?.seats }}</td>
        </tr>
        <tr *ngIf="auction?.keys">
          <td>Kluczyki</td>
          <td>{{ auction?.keys }}</td>
        </tr>
        <tr *ngIf="auction?.equipment">
          <td>Wyposażenie</td>
          <td>{{ auction?.equipment }}</td>
        </tr>
        <tr *ngIf="auction?.damage_desc">
          <td>Opis uszkodzenia</td>
          <td>{{ auction?.damage_desc }}</td>
        </tr>
        <tr *ngIf="auction?.more_defecte">
          <td>Niewidoczne uszkodzenia</td>
          <td>{{ auction?.more_defecte }}</td>
        </tr>
        <tr *ngIf="auction?.air_bag">
          <td>Wystrzelone poduszki</td>
          <td>{{ auction?.air_bag }}</td>
        </tr>
        <tr *ngIf="auction?.engine_capacity">
          <td>Pojemność silnika</td>
          <td>{{ auction?.engine_capacity }} cm3</td>
        </tr>
        <tr *ngIf="auction?.drive_type">
          <td>Rodzaj napędu</td>
          <td>{{ auction?.drive_type }}</td>
        </tr>
        <tr *ngIf="auction?.gears_number">
          <td>Liczba biegów</td>
          <td>{{ auction?.gears_number }}</td>
        </tr>
        <tr *ngIf="auction?.first_register">
          <td>Pierwsza Rejestracja</td>
          <td>{{ auction?.first_register | date: "dd.MM.yy" }}</td>
        </tr>
        <tr *ngIf="auction?.gas">
          <td>Paliwo</td>
          <td>{{ auction?.gas }}</td>
        </tr>
        <tr *ngIf="auction?.last_review">
          <td>Ostatni przegląd</td>
          <td>{{ auction?.last_review | date: "dd.MM.yy" }}</td>
        </tr>
        <tr *ngIf="auction?.type_construction_doors">
          <td>Typ Konstrukcji</td>
          <td>{{ auction?.type_construction_doors }}</td>
        </tr>
        <tr *ngIf="auction?.readyForDrive">
          <td>Gotowość do jazdy</td>
          <td>{{ auction?.readyForDrive }}</td>
        </tr>
        <tr *ngIf="auction?.registerProof">
          <td>Dowód rejestracyjny</td>
          <td>{{ auction?.registerProof }}</td>
        </tr>
        <tr *ngIf="auction?.serviceBook">
          <td>Książka serwisowa</td>
          <td>{{ auction?.serviceBook }}</td>
        </tr>
        <tr *ngIf="auction?.VIN">
          <td>VIN</td>
          <td>{{ auction?.VIN }}</td>
        </tr>
        <tr *ngIf="auction?.serwis">
          <td>Serwis</td>
          <td>{{ auction?.serwis }}</td>
        </tr>
        <tr *ngIf="auction?.tires">
          <td>Opony</td>
          <td>{{ auction?.tires }}</td>
        </tr>
        <tr *ngIf="auction?.precise_description">
          <td>Opis szczegółowy</td>
          <td>{{ auction?.precise_description }}</td>
        </tr>
        <tr *ngIf="auction?.availability">
          <td>Dostępność</td>
          <td>{{ auction?.availability }}</td>
        </tr>
        <tr *ngIf="auction?.oferrer">
          <td>Dane wystawiającego</td>
          <td>{{ auction?.oferrer }}</td>
        </tr>
        <tr *ngIf="auction?.carLocation">
          <td>Lokalizacja samochodu</td>
          <td>
            {{ auction?.carLocation }}
            <br>
            <button
              *ngIf="auction?.carLocationMap"
              (click)="showMap = !showMap"
              class="showMap"
            >
              {{ !showMap ? 'Pokaż Mape' : 'Ukryj Mapę' }}
            </button>
            <br>
            <iframe [ngClass]="showMap ? 'show' : 'hide'" [src]="trustURLMap" loading="lazy"></iframe>
          </td>
        </tr>
      </table>
      <div class="flex justify-content-center align-items-center"></div>
    </div>
    <div class="cardDamageSide p-3 w-full flex flex-wrap justify-content-around">
      <div class="flex justify-content-center">
        <table>
          <tbody>
            <tr>
              <td>
                <span
                  ><p-checkbox
                    value="frontRight"
                    [readonly]="true"
                    [binary]="true"
                    [(ngModel)]="defecteCarSide['frontRight']"
                  ></p-checkbox
                ></span>
              </td>
              <td class="text-center">
                <span
                  ><p-checkbox
                    value="right"
                    [readonly]="true"
                    [binary]="true"
                    [(ngModel)]="defecteCarSide['right']"
                  ></p-checkbox
                ></span>
              </td>
              <td>
                <span
                  ><p-checkbox
                    value="backRight"
                    [readonly]="true"
                    [binary]="true"
                    [(ngModel)]="defecteCarSide['backRight']"
                  ></p-checkbox
                ></span>
              </td>
            </tr>
            <tr>
              <td>
                <p-checkbox
                  value="front"
                  [readonly]="true"
                  [binary]="true"
                  [(ngModel)]="defecteCarSide['front']"
                ></p-checkbox>
              </td>
              <td><img alt="car top" src="assets/images/car_top.png" loading="lazy" /></td>
              <td class="text-center">
                <span
                  ><p-checkbox
                    value="back"
                    [readonly]="true"
                    [binary]="true"
                    [(ngModel)]="defecteCarSide['back']"
                  ></p-checkbox
                ></span>
              </td>
            </tr>
            <tr>
              <td>
                <span
                  ><p-checkbox
                    value="frontLeft"
                    [readonly]="true"
                    [binary]="true"
                    [(ngModel)]="defecteCarSide['frontLeft']"
                  ></p-checkbox
                ></span>
              </td>

              <td class="text-center">
                <span
                  ><p-checkbox
                    value="left"
                    [readonly]="true"
                    [binary]="true"
                    [(ngModel)]="defecteCarSide['left']"
                  ></p-checkbox
                ></span>
              </td>
              <td>
                <span
                  ><p-checkbox
                    value="backLeft"
                    [readonly]="true"
                    [binary]="true"
                    [(ngModel)]="defecteCarSide['backLeft']"
                  ></p-checkbox
                ></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-content-center">
        <table>
          <tbody>
            <tr>
              <td class="text-center">
                <span
                  ><p-checkbox
                    value="carUp"
                    [readonly]="true"
                    [binary]="true"
                    [(ngModel)]="defecteCarSide['carUp']"
                  ></p-checkbox
                ></span>
              </td>
            </tr>
            <tr>
              <td class="right"><img alt="car side" src="assets/images/car_side.png" loading="lazy" /></td>
            </tr>
            <tr>
              <td class="text-center">
                <span
                  ><p-checkbox
                    value="carDown"
                    [readonly]="true"
                    [binary]="true"
                    [(ngModel)]="defecteCarSide['carDown']"
                  ></p-checkbox
                ></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="vote-container">
      <p class="auction-text-desc more-padding">Dodaj Oferte</p>
      <form [formGroup]="offerForm" class="auction-container w-full flex align-items-center ">
        <div class="flex flex-column w-full">
          <p-inputNumber class="w-full" [formControlName]="'offer'" mode="decimal" [min]="1" [showButtons]="true"> </p-inputNumber>
          <span>Licytując akceptujesz zasady aukcji</span>
        </div>
<!--        <button-->
<!--          (click)="sendVote()"-->
<!--          class="button"-->
<!--          [ngClass]="!waitForAnswer ? 'cursor-pointer' : 'cursor-wait'"-->
<!--          [disabled]="!offerForm.valid || waitForAnswer || !showTimer"-->
<!--        >-->
<!--          Licytuj-->
<!--        </button>-->
        <p-button [ngClass]="!waitForAnswer ? 'cursor-pointer' : 'cursor-wait'" (onClick)="sendVote()" [loading]="waitForAnswer" type="submit" [disabled]="!offerForm.valid || waitForAnswer || !showTimer" label="Licytuj" class="button" styleClass=" w-full p-button-danger form-button"></p-button>

      </form>
    </div>
    <div class="message-container">
      <p class="auction-text-desc more-padding">Wyślij wiadomość</p>
      <form [formGroup]="messageForm" class="message">
        <span class="p-float-label">
          <textarea id="float-input" pInputTextarea required [formControlName]="'message'" class="w-full" matInput></textarea>
          <label for="float-input">Treść wiadomości</label>
        </span>
        <button
          (click)="sendMessage()"
          [disabled]="!messageForm.valid"
          class="send"
        >
          Wyślij
        </button>
      </form>
    </div>
  </div>
  <ng-container *ngIf="isAdmin">
    <p-table
      [value]="auction?.bids"
      [sortField]="'price'"
      [sortOrder]="-1"
      [responsiveLayout]="'scroll'"
      class="section section--full"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Numer oferty</th>
          <th [pSortableColumn]="'price'">Kwota</th>
          <th>Numer użytkownika</th>
          <th>Data licytacji</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-bid>
        <tr>
          <td>{{ bid.bids_id }}</td>
          <td>{{ bid.price }}</td>
          <td (click)="opdenDialog(bid.accounts)">{{ bid.user_id }}</td>
          <td>{{ bid.created_on | date: "dd/MM/yy, HH:mm" }}</td>
        </tr>
      </ng-template>
      <ng-template [pTemplate]="'emptymessage'">
        <tr [class]="'empty-data'">
          <td colspan="4" class="h-3rem flex justify-content-center">
            Nikt nie licytuje
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</div>

<ng-template
  #itemTemplate
  let-index="index"
  let-type="type"
  let-data="data"
  let-currIndex="currIndex">
  <ng-container *ngIf="index === currIndex">
    <img [src]="data.src">
  </ng-container>
</ng-template>
