import {
  ChangeDetectorRef,
  Component, Inject, OnDestroy,
  OnInit, PLATFORM_ID,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, MinValidator, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { ComAPIService } from '../API/com-api.service';
import { Gallery, GalleryRef } from "ng-gallery";
import { catchError, finalize, interval, of, Subject, Subscription, takeUntil } from "rxjs";
import { DialogService } from "primeng/dynamicdialog";
import { UserInfoComponent } from "../shared/components/user-info/user-info.component";
import { isPlatformBrowser, formatNumber } from "@angular/common";
import { DomSanitizer, Meta, SafeResourceUrl, Title } from "@angular/platform-browser";
import { Fancybox } from "@fancyapps/ui";

@Component({
  selector: "app-detail-auction",
  templateUrl: "./detail-auction.component.html",
  styleUrls: ["./detail-auction.component.scss"],
  providers: [DialogService]
})
export class DetailAuctionComponent implements OnInit, OnDestroy {
  endAuction = new Date("2022-04-20 00:00:00");
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  auction: any;
  galleryRef: GalleryRef;
  auctionID: string = '';
  showTimer = true;
  trustURLMap: SafeResourceUrl;
  showMap = false;
  isAdmin = false
  messageForm = new UntypedFormGroup({
    message: new UntypedFormControl(''),
  });
  gallerySpinner = true;
  offerForm = new FormGroup({
    offer: new FormControl<Number>(null, [Validators.required, Validators.min(1)]),
  });
  defecteCarSide: any = {
    // front: false,
    // frontLeft: false,
    // left: false,
  };
  fancybox: Fancybox
  front: boolean;
  selectedValues: any;
  id: number | ReturnType<typeof setTimeout> = 0;
  waitForAnswer = false;
  private subscription: Subscription;
  images: any[] =[];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private service: ComAPIService,
    private ref: ChangeDetectorRef,
    private gallery: Gallery,
    public dialogService: DialogService,
    private sanitizer: DomSanitizer,
    private metaService: Meta,
    private titleService:Title,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.auctionID = this.route.snapshot.paramMap.get('id') || '';
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.galleryRef = this.gallery.ref('myGallery');
      window.scroll({
        top: 0,
        left: 0,
        // behavior: 'smooth'
      });
      this.route.data.subscribe(
        ({auctionDetails}) => {
          this.auction = auctionDetails;
          this.addTags();
          this.trustURLMap = this.sanitizer.bypassSecurityTrustResourceUrl(auctionDetails?.carLocationMap)
          // console.log(this.auction);
          // console.log(this.auction.air_bag);

          this.auction?.defecte ? this.selectCarDamage(this.auction?.defecte) : null;

          this.auction?.images.map((item: any) => {
            this.galleryRef.addImage({
              src: `api/images/${item.images_url}`,
              thumb: `api/images/${item.images_url}?width=35&height=34`,
            });

            this.images.push({
              src: `api/images/${item.images_url}`,
              thumb: `api/images/${item.images_url}?width=97&height=72`,
            })
            this.endAuction = this.auction?.end_time;
            this.gallerySpinner = false;
            // this.ref.detectChanges();
          });
          // Fancybox.bind('myGallery', {
          //   hideScrollbar: false,
          // });
          this.subscription = interval(1000)
            .subscribe(() => { this.timeCalculator(this.auction?.end_time) });
        })
      // this.service
      //   .getAuctionDetails(this.auctionID)
      //   .pipe(
      //     takeUntil(this.destroy$),
      //     tap((auction) => {
      //       this.auction = auction;
      //       this.addTags();
      //       this.trustURLMap = this.sanitizer.bypassSecurityTrustResourceUrl(auction?.carLocationMap)
      //       // console.log(this.auction);
      //       // console.log(this.auction.air_bag);
      //
      //       this.auction?.defecte ? this.selectCarDamage(this.auction?.defecte) : null;
      //
      //       this.auction?.images.map((item: any) => {
      //         this.galleryRef.addImage({
      //           src: `api/images/${item.images_url}`,
      //           thumb: `api/images/${item.images_url}?width=35&height=34`,
      //         });
      //
      //         this.images.push({
      //           src: `api/images/${item.images_url}`,
      //           thumb: `api/images/${item.images_url}?width=97&height=72`,
      //         })
      //         this.endAuction = this.auction?.end_time;
      //         this.gallerySpinner = false;
      //         // this.ref.detectChanges();
      //       });
      //       // Fancybox.bind('myGallery', {
      //       //   hideScrollbar: false,
      //       // });
      //     })
      //   )
      //   .subscribe(() => {
      //     this.subscription = interval(1000)
      //       .subscribe(() => { this.timeCalculator(this.auction?.end_time) });
      //   });

      this.service.isAdmin().pipe(takeUntil(this.destroy$)).subscribe(answer => this.isAdmin = answer.isAdmin)
    }
  }

  addTags() {
    this.titleService.setTitle(`Swiss-Car | ${this.auction.mark} ${this.auction.model}, ${this.auction?.engine_capacity ? `${this.auction?.engine_capacity} cm3` : ''}, ${new Date(this.auction?.first_register).getFullYear()},  ${this.auction.mileage ? `${formatNumber(this.auction.mileage,"en-US", "2.")} km` : ''}`);

    this.metaService.addTags([
      { name: 'description', content: `${this.auction.mark} ${this.auction.model}, ${new Date(this.auction?.first_register).getFullYear()}, ${this.auction.mileage ? `${formatNumber(this.auction.mileage,"en-US", "2.")} km` : ''}, ${this.auction?.price_new ? `${this.auction?.price_new} CHF` : ''}` },
      { name: 'robots', content: 'index,follow' },
      { property: 'og:title', content: `Swiss-Car | ${this.auction.mark} ${this.auction.model}, ${new Date(this.auction?.first_register).getFullYear()}, ${this.auction.mileage ? `${formatNumber(this.auction.mileage,"en-US", "2.")} km` : ''}, ${this.auction?.price_new ? `${this.auction?.price_new} CHF` : ''}` },
      { property: 'og:description', content: `${this.auction.mark} ${this.auction.model},  ${this.auction?.engine_capacity ? `${this.auction?.engine_capacity} cm3,` : ''} ${new Date(this.auction?.first_register).getFullYear()}, ${this.auction?.gas ? this.auction?.gas : ''}, ${this.auction.mileage ? `${formatNumber(this.auction.mileage,"en-US", "2.")} km` : ''}, ${this.auction?.price_new ? `${this.auction?.price_new} CHF` : ''}` },
      { property: 'og:image', content: `http://swiss-car.pl/api/images/${this.auction.primary_image}` },
      { property: 'og:image:url', content: `https://swiss-car.pl/api/images/${this.auction.primary_image}` },
      { property: 'og:image:secure', content: `https://swiss-car.pl/api/images/${this.auction.primary_image}` },
      { property: 'og:image:type', content: 'image/jpeg' },
      { property: 'og:image:width', content: '1080' },
      { property: 'og:image:height', content: '600' },
      { property: 'og:type', content: `website` },
      { property: 'og:url', content: `https://www.swiss-car.pl/aukcja/${this.auction.auction_id}` },
    ]);
  }

  ngOnDestroy() {
    if (this.fancybox) {
      this.fancybox.close();
    }
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  selectCarDamage(defecte: string) {
    // console.log('defecte', defecte);
    const defecteArray = defecte?.split(',');
    // console.log('defecteArray', defecteArray);

    if (
      defecteArray.includes('Front') ||
      defecteArray.includes('damageLocationFrontCenter') ||
      defecteArray.includes('FrontMittle')
    ) {
      this.defecteCarSide['front'] = true;
    }
    if (
      defecteArray.includes('Front links') ||
      defecteArray.includes('damageLocationFrontLeft') ||
      defecteArray.includes('FrontLeft')
    ) {
      this.defecteCarSide['frontLeft'] = true;
    }
    if (
      defecteArray.includes('Front rechts') ||
      defecteArray.includes('damageLocationFrontRight') ||
      defecteArray.includes('FrontRight')
    ) {
      this.defecteCarSide['frontRight'] = true;
    }
    if (defecteArray.includes('rechts') || defecteArray.includes('RightSite') || defecteArray.includes('damageLocationRight')) {
      this.defecteCarSide['right'] = true;
    }
    if (
      defecteArray.includes('Heck rechts') ||
      defecteArray.includes('damageLocationBackRight') ||
      defecteArray.includes('BackRight')
    ) {
      this.defecteCarSide['backRight'] = true;
    }
    if (defecteArray.includes('Heck') || defecteArray.includes('BackMiddle') || defecteArray.includes('damageLocationBackCenter')) {
      this.defecteCarSide['back'] = true;
    }
    if (
      defecteArray.includes('Heck links') ||
      defecteArray.includes('damageLocationBackLeft') ||
      defecteArray.includes('BackLeft')
    ) {
      this.defecteCarSide['backLeft'] = true;
    }
    if (defecteArray.includes('links') || defecteArray.includes('LeftSite')|| defecteArray.includes('damageLocationLeft')) {
      this.defecteCarSide['left'] = true;
    }
    if (defecteArray.includes('oben') || defecteArray.includes('Roof') || defecteArray.includes('damageLocationRoof')) {
      this.defecteCarSide['carUp'] = true;
    }
    if (defecteArray.includes('unten') || defecteArray.includes('Bottom') || defecteArray.includes('damageLocationUnderBody')) {
      this.defecteCarSide['carDown'] = true;
    }

    // console.log(this.defecteCarSide);
  }

  timeCalculator(endAuction: any): void {
    const total = Date.parse(endAuction) - Date.parse(`${new Date()}`);
    this.days = Math.floor(total / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    this.minutes = Math.floor((total / 1000 / 60) % 60);
    this.seconds = Math.floor((total / 1000) % 60);
    this.ref.detectChanges();

    if (total < 0) {
      this.showTimer = false;
    }
    // console.log(total, days, hours, minutes, seconds);
  }

  sendVote() {
    const auctionID = +this.auctionID;
    const offer = +this.offerForm.get('offer')!.value;
    this.waitForAnswer = true;

    this.service
      .postBidAuction(auctionID, offer)
      .pipe(
        takeUntil(this.destroy$),
        catchError((err) => of(console.log(err))),
        finalize(() => {
          this.toastr.success('Twoja oferta została wysłana');
          this.waitForAnswer = false;
          this.offerForm.reset();
        })
      )
      .subscribe();
  }

  sendMessage() {
    // console.log(this.messageForm.value);
    this.toastr.success('Twoja wiadomość została wysłana');
    this.messageForm.reset();
  }

  opdenDialog(accounts: any) {
    this.dialogService.open(UserInfoComponent, {
      data: {
        user_data: accounts,
      },
      header: 'Dane użytkownika',
      width: '70%',
    });
  }

  itemClick($event: number) {
    console.log($event)
  }

  openGallery() {
    this.fancybox = new Fancybox(
      // Array containing gallery items
      [
        // Gallery item
        ...this.images
      ],
      // Gallery options
      {
        hideScrollbar: false,
      }
    );
  }
}
