<div #myDiv>
  <p-card class="section section--full">
    <p-table
      [value]="bids"
      [scrollable]="true"
      [rowHover]="true"
      [autoLayout]="true"
      [resizableColumns]="true"
      [showCurrentPageReport]="true"
      [sortField]="'bids_id'"
      [sortOrder]="-1"
      [loading]="loadingTable"
      [globalFilterFields]="['bids_id', 'created_on', 'last_login', 'activ']"
    >
      <ng-template pTemplate="caption">
        <div class="table-header flex justify-content-between">
          Lista wszystkich ofert
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'bids_id'">
            Id oferty
            <p-sortIcon [field]="'bids_id'"></p-sortIcon>
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'bids_id'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po ID'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('bids_id')"
                  ></button>
                  <!--              <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"-->
                  <!--                      (click)="acceptFilter()"-->
                  <!--              ></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Id użytkownika
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'user_id'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po id użytkownika'"
                />

                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('user_id')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Kwota
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                Od
                <input
                  [formControlName]="'priceStart'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po kwocie'"
                />
                Do
                <input
                  [formControlName]="'priceEnd'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po kwocie'"
                />
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('priceStart', 'priceEnd')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            Id aukcji
            <p-columnFilter
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                <input
                  [formControlName]="'auction_id'"
                  type="text"
                  pInputText
                  [placeholder]="'Szukaj po id aukcji'"
                />
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('auction_id')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
          <th [pSortableColumn]="'created_on'">
            Data oferty
            <p-sortIcon [field]="'created_on'"></p-sortIcon>
            <p-columnFilter
              field="date"
              display="menu"
              [hideOnClear]="true"
              [showClearButton]="false"
              [showApplyButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template [formGroup]="formFilter" pTemplate="filter">
                Od
                <p-calendar
                  [formControlName]="'created_onStart'"
                  [placeholder]="'Szukaj po dacie złożenia oferty'"
                  selectionMode="single"
                  [readonlyInput]="true"
                  [dateFormat]="'dd/mm/yy'"
                  inputId="single"
                >
                </p-calendar>
                Do
                <p-calendar
                  [formControlName]="'created_onEnd'"
                  [placeholder]="'Szukaj po dacie założenia'"
                  [minDate]="minDate"
                  [dateFormat]="'dd/mm/yy'"
                  selectionMode="single"
                  [readonlyInput]="true"
                  inputId="single"
                >
                </p-calendar>
                <div class="pt-2 w-100 flex justify-content-between">
                  <button
                    pButton
                    pRipple
                    type="button"
                    label="Usuń filtr"
                    class="p-button-outlined p-button p-button-sm"
                    (click)="resetFilters('created_onStart', 'created_onEnd')"
                  ></button>
                  <!--                <button pButton pRipple type="button" label="Akceptuj" class="p-button p-button-sm"></button>-->
                </div>
              </ng-template>
            </p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-bids>
        <tr>
          <td>{{ bids?.bids_id }}</td>
          <td class="flex justify-content-around">
            {{ bids?.user_id }}
            <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-search" (click)="openDialogUserInfo(bids?.accounts)"></button>
          </td>
          <td>{{ bids?.price }}</td>
          <td class="flex justify-content-around">
            <a [href]='"/aukcja/" + bids?.auction_id'>{{ bids?.auction_id }}</a>
            <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-search" (click)="openDialogAuctionInfo(bids?.auctions)"></button>

          </td>
          <td>{{ bids?.created_on | date: "dd/MM/yy, HH:mm" }}</td>
        </tr>
      </ng-template>
      <ng-template [pTemplate]="'emptymessage'">
        <tr [class]="'empty-data'">
          <td colspan="10" class="h-30rem flex justify-content-center">
            Brak wyników do wyświetlenia
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="rowPerPage"
      [rows]="visibleRows"
      [showCurrentPageReport]="true"
      [currentPageReportTemplate]="
        'Showing {first} to {last} of {totalRecords} entries'
      "
      (onPageChange)="onPageChange($event)"></p-paginator>
  </p-card>
</div>
